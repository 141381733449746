import React from "react"
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Section from "../components/Section"
import Container from "react-bootstrap/Container"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Headline from "../components/Headline"
import VimeoEmbed from "../components/VimeoEmbed"

export default class SongTemplate extends React.Component {
  render() {
    let song = this.props.data.contentfulWarriorWorshipSong;
    let album = song.album;    
    let otherSongs = (album.warrior_worship_song ? album.warrior_worship_song : []).filter(function(item) { return (item.slug !== song.slug && item.vimeoId) });
    otherSongs.sort(function(a, b){return a.orderOnAlbum - b.orderOnAlbum});
    return (
      <Layout>
        <Seo title={song.name} />
        <Section className="white" useCol={false}>
          <Col xs={(otherSongs && otherSongs.length > 0) ? 12 : 8}>
            <Headline>{song.name}</Headline>
          </Col>
          <Col xs={12} md={8}>
            { song.vimeoId && <VimeoEmbed vimeoId={song.vimeoId} useCinema={song.cinematicAspectRatio} /> }
            { !song.vimeoId && <GatsbyImage image={getImage(song.album.albumCover)} alt={song.title} /> }
            { song.description && <div className="py-3" dangerouslySetInnerHTML={{
                __html: song.description.childMarkdownRemark.html,
              }}
            />}
          </Col>
          {(otherSongs && otherSongs.length > 0) && <Col md={4}>
            <Container className="sideGridArea">
              <Row><Col xs={12}><h6>Other {album.name} Music Videos</h6></Col></Row>
              {otherSongs.map((otherSong) => {
                return (
                  <Row noGutters={true} className="py-2" key={"song-" + otherSong.slug}>
                    <Col xs={4}><Link to={`/warrior-worship/${otherSong.slug}`}><GatsbyImage image={getImage(otherSong.vimeoThumbnail)} alt={otherSong.name} /></Link></Col>
                    <Col xs={8} className="pl-2"><Link to={`/warrior-worship/${otherSong.slug}`}>{otherSong.name}</Link></Col>
                  </Row>
                )
              })}
            </Container>
          </Col>}
          <Col xs={12} className="py-5">
            &nbsp;
          </Col>
        </Section>
      </Layout>
    )
  }
}

export const songFragment = graphql`
  fragment WarriorWorshipSong on ContentfulWarriorWorshipSong {
    name
    slug
    vimeoId
    cinematicAspectRatio
    vimeoThumbnail {
      childImageSharp {
        gatsbyImageData(
          width: 370
          height: 200
          transformOptions: { cropFocus: ENTROPY }
          )
      }
    }
    downloadLink
    downloadExpires
    orderOnAlbum
    description {
      childMarkdownRemark {
        html
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`
export const pageQuery = graphql`
query SongBySlug($slug: String!) {
    contentfulWarriorWorshipSong(slug: { eq: $slug }) {
      ...WarriorWorshipSong
      album {
        name
        albumCover {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            width: 600
            )
        }
        warrior_worship_song {
          name
          slug
          vimeoId
          vimeoThumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 370
                height: 288
                )
            }
          }
          orderOnAlbum
        }  
      }  
    }
  }
`
